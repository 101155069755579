// Import core & vendor packages below
import { Editor } from '@tinymce/tinymce-react';
// Components

// Main component
const TextEditor = ({ text, setText }) => {
  return (
    <Editor 
      apiKey={process.env.REACT_APP_TINYMCE_API}
      value={text}
      onEditorChange={(e) => { setText(e); }}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar: 'undo redo | formatselect | h1 h2 h3 h4 h5 h6 ' +
        'bold italic underline backcolor forecolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'removeformat | help',
        contentStyle: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
    />
  );
};

export default TextEditor;
