import React from 'react';
import { Outlet } from 'react-router';
import Header from './components/header';

const Engage = () => (
    <>
        <Header/>
            <Outlet/>
    </>
 
       
    
)
export default Engage