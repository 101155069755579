import React from 'react';
import { motion } from 'framer-motion';

const LineReveal = () => {
  const lineReveal = {
    initial: {
      width: '0%',
    },

    active: {
      width: '100%',
    },

    exit: {
      width: '0%',

    },
  };

  return (
    <motion.hr
      variants={lineReveal}
      initial='initial'
      whileInView='active'
      exit='exit'
      transition={{ duration: 1, ease: 'easeInOut', when: 'beforeChildren' }}
    />
  );
};

export default LineReveal;