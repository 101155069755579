import { Box, Stack, Text, Flex, Button, Link, Spacer } from '@chakra-ui/react';
import RevealingLetters from 'components/GlobalComponents/RevealingLetters';
import 'components/Styles/Footer.scss';

export default function Footer() {

  return (
    <Box
      bg='#0E0E0EF2'
      color='#FFFCF1'
      paddingX={['4vw', '4vw']}
      className={'footer'}
    >
        <Flex flexWrap='wrap' justifyContent='space-between' position={'relative'} paddingTop={['4vh', '4vh', '8vh']}>
          <Box
            flexBasis={['100%', '100%', '75%']} 
            marginBottom={['20px', '20px', '20px']}
          >
            <Text fontSize='2xl' className={'get-in-touch'}>Get in touch.</Text>
            <Button variant='outline' 
              color='white' 
              borderColor='white'
              height='52px'
              _hover={{
                background: 'white',
                color: 'black',
              }}
              className={'contact-button'}
            >
              Contact Us
            </Button>
          </Box>

          <Spacer />

          <Box
            flexBasis={['50%', '50%', '16%']} 
            marginBottom={['20px', '20px', '20px']}
          >
            <Stack spacing={0}>
              <Link href='#' _hover={{ color: 'white' }}><span className='hover-underline-animation'>Home</span></Link>
              <Link href='#' _hover={{ color: 'white' }}><span className='hover-underline-animation'>Solution</span></Link>
              <Link href='#' _hover={{ color: 'white' }}><span className='hover-underline-animation'>About</span></Link>
              <Link href='#' _hover={{ color: 'white' }}><span className='hover-underline-animation'>Contact</span></Link>
            </Stack>
          </Box>
          <Box flexBasis={['50%', '50%', '5%']} marginBottom={['20px', '20px', '20px']}>
            <Stack spacing={0}>
              <Link href='#' _hover={{ color: 'white' }}><span className='hover-underline-animation'>X</span></Link>
              <Link href='#' _hover={{ color: 'white' }}><span className='hover-underline-animation'>Facebook</span></Link>
              <Link href='#' _hover={{ color: 'white' }}><span className='hover-underline-animation'>Instagram</span></Link>
              <Link href='#' _hover={{ color: 'white' }}><span className='hover-underline-animation'>LinkedIn</span></Link>
              <Link href='#' _hover={{ color: 'white' }}><span className='hover-underline-animation'>YouTube</span></Link>
            </Stack>
          </Box>
        </Flex>

        <Box h={['62px', '62px', '122px']} />

        <Flex flexWrap='wrap' justifyContent='space-between' position={'relative'} className={'footer-contact'}>
          <Box width='264px' className='footer-address'>
            <Text>3rd Flr., Royal Plaza Commercial Center, DM Guevarra St. Mandaluyong City, Philippines 1550</Text>
          </Box>

          <Spacer />

          <Box marginRight='88px' marginTop={'24px'}>
            <Link href='mailto:support@highlysucceed.com' _hover={{ color: 'white' }}><span className='hover-underline-animation'>support@highlysucceed.com</span></Link>
          </Box>
          
          <Box marginTop={'24px'}>
            <Text>©2023 <Link href='#' _hover={{ color: 'white' }} textDecor={'underline'}><span className='hover-underline-animation'>Highly Succeed Inc.</span></Link>
            </Text>
          </Box>
        </Flex>

        <Flex justify='center'>
          <Box id='highlysucceed-container' style={{ whiteSpace: 'nowrap' }}>
            <Text className='hsi'><RevealingLetters text='highlysucceed' /></Text>
          </Box>
        </Flex>
    </Box>
  );
}
