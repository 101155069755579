import { store } from './app/store';
import { Provider } from 'react-redux';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';

import App from './App';

// import assets below
import 'assets/sass/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <ChakraProvider>
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
  </ChakraProvider>,
);
