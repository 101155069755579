import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

const parentVariants = {
    initial: { transition: { staggerChildren: 0.07, delayChildren: 0.02 } },
    inview: { transition: { staggerChildren: .10, ease: 'easeOut' } },
};

const spanVariants = {
    initial: {
        y: '100%',
        transition: {
            bottom: { stiffness: 1000, velocity: -100 },
        },
    },
    inview: {
        y: 0,
        transition: { stiffness: 1000, ease: 'easeOut', duration: .45 },
    },
};

const StaggeredText = ({ content, customFontSize }) => {
    const contentRef = useRef(null);
    const [lines, setLines] = useState([]);

    function collapseWhiteSpace(value) {
        return (value.trim().replace(/\s+/g, ' '));
    }

    function extractPerLine(textNode) {
        textNode.textContent = collapseWhiteSpace(textNode.textContent);

        const textContent = textNode.textContent;
        const range = document.createRange();
        let lines = [];
        let lineCharacters = [];

        for (let i = 0; i < textContent.length; i++) {

            range.setStart(textNode, 0);
            range.setEnd(textNode, (i + 1));

            const lineIndex = (range.getClientRects().length - 1);

            if (!lines[lineIndex]) {
                lines.push(lineCharacters = []);
            }

            lineCharacters.push(textContent.charAt(i));
        }

        lines = lines.map((characters) => collapseWhiteSpace(characters.join('')));
        return lines;
    }

    function getFontSize() {
        return customFontSize;
    }

    useEffect(() => {

        const initializeStagger = () => {
            const textNode = contentRef.current.firstChild;
            setLines(extractPerLine(textNode));
        };

        initializeStagger();

        const windowResize = window.addEventListener('resize', initializeStagger);

        return () => { window.removeEventListener('resize', windowResize); };
        
    }, []);

    return (
        <>
            <div style={{ maxHeight: 0, opacity: 0, fontSize: getFontSize() }} ref={contentRef}>{content}</div>
            <motion.p
              variants={parentVariants}
              initial='initial'
              whileInView='inview'
              style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: getFontSize() }}
            >

                {lines.map((line, index) => (
                    <span
                      key={index}
                      style={{ position: 'relative', overflow: 'hidden', maxHeight: 'fit-content' }}
                    >

                        <motion.span
                          variants={spanVariants}
                          style={{ display: 'inline-block', fontSize: getFontSize() }}
                        >

                            {line}
                        </motion.span>

                    </span>
                ))}
            </motion.p>
        </>
    );
};

export default StaggeredText;