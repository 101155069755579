import React, { useState, useRef, useEffect } from 'react';
import { Box, Text, Flex, Link, Divider, VStack, Image, Button } from '@chakra-ui/react';
import { motion } from 'framer-motion';

// Components
import PrivateLayout from 'components/GlobalComponents/PrivateLayout';
import TextEditor from 'components/GlobalComponents/TextEditor';

import styles from './index.module.scss';
import ContactPortfolioImg from 'assets/images/Portfolio_Section.png';
import Polygon1 from 'assets/images/Polygon_1.svg';
import Polygon2 from 'assets/images/Polygon_2.png';
import Polygon3 from 'assets/images/Polygon_3.svg';
import PortfolioArrow from 'assets/images/Porfolio_Arrow.svg';
import SlideUpText from 'components/GlobalComponents/SlideUpText';

const Contact = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [wholeContent, setWholeContent] = useState(`
    <div>
      <p style="color: #202123; line-height: 1;">Email:</p>   
      <a style="color: #EFC72F; display: 'block'; text-decoration: 'underline'; " href='mailto:support@highlysucceed.com'>support@highlysucceed.com</a> 
    </div>
    <div>
      <p style="color: #202123;">Contact:</p>
      <p style="color: #EFC72F;">(+63) 9171041016</p>
    </div>
    <div>
      <p style="color: #202123;"> Address:</p>
      <p style="color: #EFC72F;">3rd Flr., Royal Plaza Commercial Center, DM Guevarra St. Mandaluyong City, Philippines 1550</p>
    </div>
  `);
  const pageRef = useRef(null);
    useEffect(() => {
        console.log('pageRef', pageRef.current.innerHTML);
    }, [pageRef]);

  return (
    
    <PrivateLayout pageTitle='Contact'>
      <Box className={styles?.contact}>
        <Image src={Polygon1} className={styles?.polygon1} />

        <Flex className={styles?.container}>
          <Box
            flexBasis={['100%', '100%', '60%']}
            marginBottom={'60px'}
            zIndex={'2'} 
          >
            <Box className={styles?.heading}>
                <Text color='#EFC72F'><SlideUpText text={['Connect with us.']} delay={[0.1]} /></Text>
                <Text color='#000'>
                  <SlideUpText text={['Find out more and send us a message!']} delay={[0.2]} />
                </Text>
            </Box>
          </Box>
          <Box
            flexBasis={['100%', '100%', '35%']}
            marginBottom={'60px'}
            zIndex={'2'} 
            className={styles?.contact_details}
            position={'relative'}
          >
            <motion.button whileHover={{ opacity: 1, scale: 1.1 }} whileTap={{ opacity: 1, scale: .9 }} className={styles?.editNSave} onClick={() => { setIsEdit((isEdit) => !isEdit); }}>{isEdit ? 'Save' : 'Edit'}</motion.button>
            <span ref={pageRef}></span>
            {isEdit ? (
                <TextEditor text={wholeContent} setText={setWholeContent} />
            ) : (
                <div dangerouslySetInnerHTML={{ __html: wholeContent }}></div>
            )}
          </Box>
          <Divider borderWidth='1px' borderColor='#000' position={'relative'} zIndex={'0'} marginTop={'110px'} marginBottom={'4.444vw'} />
        </Flex>

        <Image src={Polygon2} position={'absolute'} left={'0'} zIndex={'1'} className={styles?.polygon2} />

        <Box className={styles?.contact_form}>
              <form>
                  <label htmlFor='full-name'>Full Name</label>
                  <input type='text' id='full-name' name='full-name' placeholder='Enter Name' required></input>

                  <label htmlFor='email'>Email Address</label>
                  <input type='email' id='email' name='email' placeholder='Enter Email Address' required></input>

                  <label htmlFor='phone'>Phone Number</label>
                  <input type='tel' id='phone' name='phone' placeholder='Enter Number' required></input>

                  <label htmlFor='subject'>Subject</label>
                  <input type='text' id='subject' name='subject' placeholder='Enter Subject' required></input>

                  <label htmlFor='message'>Enter Message</label>
                  <textarea id='message' name='message' placeholder='Enter Message' rows='4' required></textarea>

                  <div>
                    <Button variant='solid' bg='#EFC72F' color='white' _hover={{}} paddingX={'2.5rem'} marginY={'78px'}>
                      Submit
                    </Button>
                  </div>
                </form>
          <Image src={Polygon3} className={styles?.polygon3} />
        </Box>

            <Box position={'relative'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Image src={ContactPortfolioImg} alt='Our Solutions Portfolio' width={'100%'} />

              <VStack
                position='absolute'
                textAlign='center'
                color='white'
                zIndex='1'
              >
                <Text fontSize='32px' display={'inline-flex'} alignItems={'center'} color={'#FAFAFA'} fontWeight={'500'} lineHeight={'120%'}>
                  Portfolio <Image src={PortfolioArrow} ml={'20px'} />
                </Text>
                <Link href='#' className={styles?.solutions_link}><SlideUpText text={['Our Solutions']} delay={[0.1]} /></Link>
              </VStack>
            </Box>
        </Box>
    </PrivateLayout>
  );
};

export default Contact;
