/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import React, { useState } from 'react';
import { Navbar, Container, Offcanvas, Nav, NavDropdown, Form, Button } from 'react-bootstrap';

// Components
import ReactHelmet from './ReactHelmet';
import NavBar from 'components/Layouts/NavBar';
import Footer from 'components/Layouts/Footer';

// Assets
import 'components/Styles/PrivateLayout.scss';

const PrivateLayout = ({ children, pageTitle }) => {
  return (
    <>
      <ReactHelmet title={pageTitle} />

      <div className='main-page'>
        <NavBar />

        <div className='main-container overflow-hidden'>{children}</div>
        
        <Footer />
      </div>
    </>
  );
};

export default PrivateLayout;
