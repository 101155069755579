/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Image, Container, Row, Col } from 'react-bootstrap';

// Components
import PrivateLayout from 'components/GlobalComponents/PrivateLayout';

// Assets
import styles from './index.module.scss';

// Main component
const PageNotFound = () => {
  return (
    <PrivateLayout pageTitle='404 Page not found'>
      <Container>
        <Row>
          <Col>
            <div className={styles.notfound}>
              <h2>ERROR 404: Page not Found</h2>
              <p>
                Click <Link to='/'>here</Link> to go back to homepage
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default PageNotFound;
