import React, { useState } from 'react';
import { Box, Image, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Card, CardBody, Input, Button, Flex, Spacer, Badge, ButtonGroup, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, FormControl, FormLabel, Alert, AlertIcon, AlertTitle, AlertDescription, Checkbox, Stack } from '@chakra-ui/react';
// import { Select } from 'chakra-react-select';

// Components
import AdminLayout from 'components/GlobalComponents/AdminLayout';

import styles from './index.module.scss';

import PolygonAdmin from 'assets/images/Polygon_Admin.png';

const AdminUsers = () => {
  return (
    <AdminLayout pageTitle='Users'>
      <Box className={styles?.pages}>  
        <Image src={PolygonAdmin} className={styles?.polygon_admin} />
        <Box p={5}>  
          <p className={styles?.p}>Users</p>

          <Card>
            <CardBody>
              <Flex alignItems='center' marginBottom={'22px'}>
              <Input
                placeholder='Filter Users'
                size='sm'
                type='search'
                width={'506px'}
              />
                <Spacer />
                <NewUserModal />
              </Flex>
            
              <Card>
                <CardBody>
                  
                  <TableContainer>
                  <Table variant='striped' colorScheme='gray'>
                    <Thead fontStyle={'normal'}>
                      <Tr>
                        <Th>Username</Th>
                        <Th>Status</Th>
                        <Th>Created</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>admin1</Td>
                        <Td><Badge colorScheme='green'>Active</Badge></Td>
                        <Td>Sept 13, 2023</Td>
                        <Td>
                          <ButtonGroup spacing='2'>
                            <EditUserModal />
                            <DeleteUserModal />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>admin2</Td>
                        <Td><Badge colorScheme='green'>Active</Badge></Td>
                        <Td>Sept 13, 2023</Td>
                        <Td>
                          <ButtonGroup spacing='2'>
                            <EditUserModal />
                            <DeleteUserModal />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>admin3</Td>
                        <Td><Badge colorScheme='green'>Active</Badge></Td>
                        <Td>Sept 15, 2023</Td>
                        <Td>
                          <ButtonGroup spacing='2'>
                            <EditUserModal />
                            <DeleteUserModal />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>admin4</Td>
                        <Td><Badge colorScheme='green'>Active</Badge></Td>
                        <Td>Sept 18, 2023</Td>
                        <Td>
                          <ButtonGroup spacing='2'>
                            <EditUserModal />
                            <DeleteUserModal />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>temp</Td>
                        <Td><Badge colorScheme='red'>Disabled</Badge></Td>
                        <Td>Sept 18, 2023</Td>
                        <Td>
                          <ButtonGroup spacing='2'>
                            <EditUserModal />
                            <DeleteUserModal />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Box>
      </Box>
    </AdminLayout>
  );
};

function OverlayAlert({ isAlertVisible, status, title, description }) {
  return (
    <Box position='fixed' top='92' right='4' zIndex='9999'>
      {isAlertVisible && (
        <Alert status={status} variant='top-accent' borderRadius='md' boxShadow='md' minW='400px'>
          <AlertIcon boxSize='2em' mr={4} />
          <Box flex='1'>
            <AlertTitle mr={2}>{title}</AlertTitle>
            <AlertDescription>{description}</AlertDescription>
          </Box>
        </Alert>
      )}
    </Box>
  );
}

function NewUserModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const onCloseAlert = () => {
    setIsAlertVisible(false);
  };

  const showAlert = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      onCloseAlert();
    }, 5000);
  };

  return (
    <>
      <Button backgroundColor={'#EFC72F'} size='md' onClick={onOpen}>New User</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl marginY='2'>
              <h5>New User</h5>
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Name</FormLabel>
                <Input type='text' />
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Username</FormLabel>
                <Input type='text' />
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Password</FormLabel>
                <Input type='password' />
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Confirm Password</FormLabel>
                <Input type='password' />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>Cancel</Button>
            <Button backgroundColor={'#EFC72F'} onClick={() => { onClose(); showAlert(); }}>
              Add User
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <OverlayAlert isAlertVisible={isAlertVisible} status='success' title='Account Created' description='Operation Successful' />
    </>
  );
}

function EditUserModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const onCloseAlert = () => {
    setIsAlertVisible(false);
  };

  const showAlert = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      onCloseAlert();
    }, 5000);
  };

  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setInputValue('');
    }
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <>
      <Button backgroundColor={'#FAFAFA'} size='md' onClick={onOpen}>Edit</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl marginY='2'>
              <h5>Edit User</h5>
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Name</FormLabel>
                <Input type='text' />
            </FormControl>
            <FormControl marginBottom='8'>
                <FormLabel>Username</FormLabel>
                <Input type='text' />
            </FormControl>
            <Stack spacing={2} direction='column'>
              <Checkbox colorScheme='yellow' isChecked={isChecked} onChange={onCheckboxChange}>
                Reset Password?
              </Checkbox>
              {isChecked && (
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input
                      marginBottom={2}
                      placeholder='Password'
                      value={inputValue}
                      onChange={onInputChange}
                      type='password'
                    />
                </FormControl>
              )}
              <Checkbox colorScheme='yellow'>
                Disable Account?
              </Checkbox>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant={'ghost'} mr={3}>Cancel</Button>
            <Button backgroundColor={'#EFC72F'} onClick={() => { onClose(); showAlert(); }}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <OverlayAlert isAlertVisible={isAlertVisible} status='success' title='Account Modified' description='Operation Successful' />
    </>
  );
}

function DeleteUserModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const onCloseAlert = () => {
    setIsAlertVisible(false);
  };

  const showAlert = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      onCloseAlert();
    }, 5000);
  };

  return (
    <>
      <Button colorScheme='red' size='md' onClick={onOpen}>Delete</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl marginY='2'>
              <h5>Delete User</h5>
            </FormControl>
            Are you sure you want to delete this user?
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>Cancel</Button>
            <Button colorScheme='red' onClick={() => { onClose(); showAlert(); }}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <OverlayAlert isAlertVisible={isAlertVisible} status='success' title='Account Deleted' description='Operation Successful' />
    </>
  );
}

export default AdminUsers;
