import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
// Components
import PrivateLayout from 'components/GlobalComponents/PrivateLayout';

import styles from './index.module.scss';
import { Flex, Image, Box, Divider } from '@chakra-ui/react';

import mwellImg from 'assets/images/mwell.png';
import hsiImg from 'assets/images/hsi_services.png';
import magriImg from 'assets/images/magri.png';

import image1 from 'assets/images/image1.png';
import image2 from 'assets/images/image2.jpg';
import image3 from 'assets/images/image3.jpg';
import image4 from 'assets/images/image4.jpg';
import image5 from 'assets/images/image5.png';

import LineReveal from '../../components/GlobalComponents/LineReveal';
import StaggeredText from 'components/GlobalComponents/StaggeredText';
import RevealingLetters from 'components/GlobalComponents/RevealingLetters';
// import heroVideo from 'assets/webm/homepage-hero.webm';

const Homepage = () => {
  const cursorRef = useRef(null);
  const customCursorSectionRef = useRef(null);
  const fontSizes = [
    ['5rem', '4.5rem', '4.25rem', '4rem', '2.25rem'], // H1 sizes
    ['4.25rem', '4rem', '2.25rem', '2rem', '1.75rem'], // H2 sizes
    ['1.75rem', '1.75rem', '1.5rem', '1.25rem', '1rem'], // H3 sizes
  ];

  // Function to calculate font size based on screen width
  const calculateFontSize = (index) => {
    const screenWidth = window.innerWidth;
    
    if (screenWidth >= 1920) return fontSizes[index][0];
    if (screenWidth >= 1400) return fontSizes[index][1];
    if (screenWidth >= 767) return fontSizes[index][2];
    if (screenWidth >= 543) return fontSizes[index][3];
    if (screenWidth >= 300) return fontSizes[index][4];
    return '1rem';
  };

  // Update font size when the window is resized
  useEffect(() => {
    const handleResize = () => {
      // Calculate font size for H1 and H2 based on screen width
      const fontSizeH1 = calculateFontSize(0); // H1 sizes
      const fontSizeH2 = calculateFontSize(1); // H2 sizes
      const fontSizeH3 = calculateFontSize(2); // H2 sizes
      
      // Set font size based on the calculated values
      document.documentElement.style.setProperty('--font-size-h1', fontSizeH1);
      document.documentElement.style.setProperty('--font-size-h2', fontSizeH2);
      document.documentElement.style.setProperty('--font-size-h3', fontSizeH3);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    const lastCursorPosition = {
      x: 0,
      y: 0,
    };

    const moveCustomCursor = (e) => {
      const customCursor = cursorRef.current;
      const divCenter = customCursorSectionRef.current;
    
      const divX = divCenter.offsetWidth / 2;
      const divY = (divCenter.offsetHeight - ((10 * divCenter.offsetHeight) / 100)) / 2;

      let x = (e.pageX ?? lastCursorPosition.x) - customCursor.offsetWidth / 2;
      let y = (e.pageY ?? (lastCursorPosition.y + window.scrollY)) - customCursor.offsetHeight / 2;

      lastCursorPosition.x = e.pageX ?? lastCursorPosition.x;

      if (e.pageY) {
        lastCursorPosition.y = divCenter.getBoundingClientRect().bottom < e.pageY ? e.clientY : e.pageY;
      }

      x = x + ((x - divX) / 5);
      y = y + ((y - divY) / 5);
      
      const keyframes = {
        transform: `translate(${x}px, ${y}px)`,
      };

      customCursor.animate(keyframes, {
        duration: 2000,
        fill: 'forwards',
      });
    };

    const mouseMove = window.addEventListener('mousemove', moveCustomCursor);
    const windowScroll = window.addEventListener('scroll', moveCustomCursor);

    return () => {  
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('scroll', windowScroll);
    };
  }, []);

  return (
    <PrivateLayout pageTitle='Home Page'>
      
      <section ref={customCursorSectionRef} className={styles?.home}>
        <div ref={cursorRef} className={styles?.circleCursor}></div>
        {/* <video>
          <source src={heroVideo} type='video/webm' />
        </video> */}
        <motion.div
          className={styles?.initialAnimation}
          initial={{ transform: 'translateY(0vh)' }}
          animate={{ transform: 'translateY(-100vh)' }}
          transition={{ duration: 0.5, delay: 2.5 }}
        >
          <RevealingLetters text='highlysucceed' />
        </motion.div>
        <h1 className={styles?.mainText}>highlysucceed</h1>
      </section>

      <section className={styles?.section2}>
        
        <div className={styles?.contentCont}>
          <div className={styles?.section2Top}>
            
            <div className={styles?.topContainer}>
              <StaggeredText customFontSize={'var(--font-size-h3)'} content='Full service agency.' />
              <StaggeredText customFontSize={'var(--font-size-h3)'} content='Two engagement models.' />
            </div>  

            <div>
              <StaggeredText customFontSize={'var(--font-size-h3)'} content='Philippines' />
            </div>
            
          </div>

          <LineReveal />
          <div className={styles?.section2Bottom}>
            {/* <StaggeredText customFontSize={'var(--font-size-h1)'} content={section2MainContent} /> */}
            <p><span>Highly Succeed</span> is a Philippines-based IT servicing and product company that specializes on a wide range of web based services. We are composed of professionals in graphic design, web development, custom application creation, and mobile application.</p>
          </div>
        </div>
      </section>

      <section className={styles?.section3}>
        <div>
          <StaggeredText customFontSize={'var(--font-size-h1)'} content='Explore our solutions' />
          <StaggeredText customFontSize={'var(--font-size-h1)'} content='for your business' />
        </div>
      </section>

      <section className={styles?.section4}>
        <div className={styles?.topContainer}>
          <h5>Let your business swiftly adapt to the ever-changing market forces through our viable digital solutions</h5>
          
          <div className={styles?.icon_box}>
            <h5>Our Solutions</h5>
            <svg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M2.26469 4.53434L2.26469 1.51145L2.26469 8.99723e-07L1.13235 9.68863e-07C0.832037 2.10266e-05 0.544039 0.0796485 0.331688 0.22137C0.119337 0.363091 2.67724e-05 0.555301 -1.81275e-06 0.755725L-1.24519e-06 5.29007C2.73901e-05 5.49049 0.119338 5.6827 0.331689 5.82442C0.54404 5.96614 0.832038 6.04577 1.13235 6.04579L13.1193 6.04579L10.5229 7.77864C10.4142 7.84828 10.3274 7.93169 10.2677 8.02398C10.2079 8.11628 10.1763 8.21561 10.1748 8.31615C10.1732 8.41669 10.2018 8.51643 10.2587 8.60952C10.3157 8.7026 10.3999 8.78717 10.5064 8.85827C10.6129 8.92937 10.7397 8.98556 10.8791 9.02357C11.0186 9.06157 11.1681 9.08062 11.3187 9.0796C11.4694 9.07858 11.6182 9.0575 11.7565 9.01761C11.8948 8.97772 12.0198 8.91981 12.1241 8.84728L16.5518 5.89228L16.5584 5.88933C16.6962 5.81854 16.8076 5.72754 16.8842 5.62336C16.9608 5.51918 17.0004 5.40464 17 5.2886C16.9996 5.17256 16.9591 5.05815 16.8818 4.95423C16.8044 4.8503 16.6923 4.75966 16.554 4.68933L12.1241 1.73285C11.919 1.59197 11.6398 1.50992 11.3456 1.50407C11.1175 1.49958 10.8927 1.54118 10.7006 1.62343C10.5086 1.70567 10.3582 1.82472 10.2693 1.96497C10.1804 2.10522 10.157 2.26013 10.2022 2.4094C10.2475 2.55867 10.3593 2.69534 10.5229 2.80149L13.1193 4.53434L2.26469 4.53434Z' fill='#202123' />
            </svg>
          </div>
        </div>

        {/* <Grid gap={2} templateRows={{ sm:'repeat(2, 50%)', md:'repeat(1, 1fr)' }} templateColumns={{ sm:'repeat(2, 1fr)', md:'repeat(3, 1fr)' }}>
          <Image h='100%' src={mwellImg} alt='Mwell' />
          <Image h='100%' src={hsiImg} alt='Dan Abramov' />
          <Image h='100%' src={magriImg} alt='Dan Abramov' />
        </Grid> 
        flexDirection={{ base:'column', md:'row' }}
        */}

        <Flex flexDirection={{ base:'column', sm:'row' }} gap='5'>
          <Box flex='1'>
            <Image w='100%' objectFit={'cover'} src={mwellImg} alt='Mwell' />
          </Box>
          <Box flex='1'>
            <Image w='100%' objectFit={'cover'} src={hsiImg} alt='Mwell' />
          </Box>
          <Box flex='1'>
            <Image w='100%' objectFit={'cover'} src={magriImg} alt='Mwell' />
          </Box>
        </Flex>
      </section>

      <section className={styles?.section5}>
        <div className={styles?.topContainer}>
          <StaggeredText customFontSize={'var(--font-size-h3)'} content='Throughout the years we have:' />
          <LineReveal />
        </div>

        <div className={styles?.counterContainer}>
          <p><span>300+</span> Projects,</p>
          <p><span>100+</span> Local Clients,</p>
          <p><span>10+</span> International Clients,</p>
          <p><span>90%</span> Customer Retention,</p>
        </div>
      </section>

      <section className={styles?.section6}>
        <Flex flexWrap='wrap' justifyContent='space-between' className={styles?.container} position={'relative'}>
          <Box
            flexBasis={'90%'}
            marginBottom={'20px'}
            zIndex={'2'} 
          >
            <p className={styles?.header}>Book a free consultation with us now!</p>
          </Box>
          <Box
            flexBasis={'10%'}
            marginBottom={'20px'}
            zIndex={'2'} 
          >
            <p>Contact</p>
          </Box>
        </Flex>
        <Divider borderWidth='1px' borderColor='#000' position={'relative'} zIndex={'0'} />

        <div className={styles?.slider}>
          <logo-slider>
          <div>
            <img src={image1} />
            <img src={image2} />
            <img src={image3} />
            <img src={image4} />
            <img src={image5} />
          </div>
          <div>
            <img src={image1} />
            <img src={image2} />
            <img src={image3} />
            <img src={image4} />
            <img src={image5} />
          </div>
          
          </logo-slider>
        </div>
      </section>
    </PrivateLayout>
  );
};

export default Homepage;
