// Import core & vendor packages below
import { Form } from 'react-bootstrap';

// Components
import { camelize } from 'utils/camelize';
import { handleTextChange } from 'helpers/handleTextChange';

// Main component
const FormText = ({ name, type, placeholder, stateValue, setState, ...rest }) => {
  return (
    <Form.Group className='global-form-input'>
      <Form.Label>{name}</Form.Label>

      <Form.Control
        {...rest}
        type={type}
        value={stateValue}
        name={camelize(name)}
        placeholder={placeholder}
        onChange={handleTextChange(setState)}
      />
    </Form.Group>
  );
};

export default FormText;
