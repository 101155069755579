import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
} from '@chakra-ui/react';

import {
  FiHome,
  FiCopy,
  FiKey,
  FiLogOut,
  FiMenu,
  FiChevronDown,
} from 'react-icons/fi';

import 'components/Styles/AdminLayout.scss';
import HSILogo from 'assets/images/HSI_Logo.png';
import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';

const LinkItems = [
  { name: 'Dashboard', icon: FiHome, route: '/admin/dashboard' },
  { name: 'Pages', icon: FiCopy, route: '/admin/pages' },
  { name: 'Admin Users', icon: FiKey, route: '/admin/users' },
  { name: 'Logout', icon: FiLogOut, route: '/login' },
];

const SidebarContent = ({ onClose, activePage, ...rest }) => {
  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      zIndex={1} 
      position={'absolute'}
      width={'100%'}
      {...rest}
      className={'sidebar'}
    >
      <Flex h='20' alignItems='center' mx='8' justifyContent='space-between'>
        <Image src={HSILogo} alt='Highly Succeed Logo' w='98px' />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link, index) => (
        <NavItem key={link.name} icon={link.icon} isLastItem={index === LinkItems.length - 1} isActive={activePage === link.name} route={link.route}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, isLastItem, isActive, route, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box
      as='a'
      href='#'
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align='center'
        p='4'
        mx='4'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        color={ isLastItem ? 'red' : '#202123'}
        border={ isLastItem ? '1px solid red;' : '#202123'}
        bg={isActive ? '#EFC72F' : 'inherit'}
        fontSize='18'
        _hover={{
          bg: '#EFC72F',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr='4'
            fontSize='16'
            fontWeight={'600'}
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        <span>{children}</span>
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      className={'header'}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        icon={<FiMenu />}
      />

      <Image src={HSILogo} alt='Highly Succeed Logo' w='98px' display={{ base: 'flex', md: 'none' }} />

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems='flex-start'
                  spacing='1px'
                  ml='2'
                >
                  <Text fontSize='sm' m={'0'}>Juan Dela Cruz</Text>
                  <Text fontSize='xs' color='gray.600' m={'0'}>
                    admin@highlysucceed.com
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider />
              <MenuItem>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
  }, []);
  return (
    <Box>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} 
        zIndex={1} 
        position={'sticky'}
        // width={'100%'} 
      />
      <Box ml={{ base: 0, md: 60 }}>
       {children}
      </Box>
    </Box>
  );
};

export default SidebarWithHeader;
