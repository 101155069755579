import React from 'react';
import {
    Box,
    Text,
    Image,
    Icon,
  } from '@chakra-ui/react'
  import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaTwitter} from "react-icons/fa";
  import Logo from '../assets/logo.png'
const Header = () => {
    return ( 
        <>
            <Box display={'flex'} flexDir={'row'} w={'100%'} px={10} py={20} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'}>
                    <Image src={Logo} w={'10%'} mr={5} mb={5}/>
                    <Text fontFamily={'monospace'} letterSpacing={2} fontSize={26}>HIGHLY SUCCEED</Text>&nbsp;
                    <Text fontFamily={'monospace'} fontWeight={'bold'} color={'yellow.500'} fontSize={26} letterSpacing={2}>ENGAGE</Text>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Icon as={FaFacebookSquare} fontSize={'35px'} mx={2} color={'blackAlpha.700'}/>
                    <Icon as={FaInstagramSquare} fontSize={'35px'} mx={2} color={'blackAlpha.700'}/>
                    <Icon as={FaLinkedin} fontSize={'35px'} mx={2} color={'blackAlpha.700'}/>
                    <Icon as={FaTwitter} fontSize={'35px'} mx={2} color={'blackAlpha.700'}/>
                </Box>
            </Box>      
        </>
     );
}
 
export default Header;