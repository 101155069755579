import { configureStore } from '@reduxjs/toolkit';

import { sampleReducer } from 'reducers/sampleReducer';
import { authReducer } from 'reducers/authReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    sample: sampleReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
