import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Public Routes
import Contact from 'pages/Contact';
import PageNotFound from 'pages/404';
import Homepage from 'pages/Homepage';
import Solution from 'pages/Solution';
import About from 'pages/About';
import Login from 'pages/Login';
import Pages from 'pages/AdminPages';
import AdminPagesEdit from 'pages/AdminPagesEdit';
import AdminUsers from 'pages/AdminUsers';
import Engage from 'pages/Engage';
import Inquiry from 'pages/Engage/inquiry/inquiry';

const SystemRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Homepage />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/solution' element={<Solution />} />
      <Route path='/about' element={<About />} />
      <Route path='/login' element={<Login />} />
      <Route path='/admin/pages' element={<Pages />} />
      <Route path='/admin/pages/edit/:id' element={<AdminPagesEdit />} />
      <Route path='/admin/users' element={<AdminUsers />} />
      <Route  path='/engage' element={<Engage/>}>
        <Route path='inquiry' element={<Inquiry/>}
      />
      </Route>
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
};

export default SystemRoutes;
