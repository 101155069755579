/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import React, { useState } from 'react';
import { Navbar, Container, Offcanvas, Nav, NavDropdown, Form, Button } from 'react-bootstrap';
import { Image } from '@chakra-ui/react';

// Components
import ReactHelmet from './ReactHelmet';
import Sidebar from 'components/Layouts/Sidebar';

// Assets
import 'components/Styles/AdminLayout.scss';

const AdminLayout = ({ children, pageTitle }) => {
  return (
    <>
      <ReactHelmet title={pageTitle} />

      <div className='main-page'>
        <Sidebar activePage={pageTitle}>{children}</Sidebar>
      </div>
    </>
  );
};

export default AdminLayout;
