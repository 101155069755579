/* eslint-disable no-unused-expressions */
// Core & Vendor Packages
import React, { useState } from 'react';
import { motion } from 'framer-motion';
/*
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Navbar, Container, OverlayTrigger, Popover, Row, Col, Button } from 'react-bootstrap';

// Components
import { logout } from 'reducers/authReducer';
// import Sidebar from 'components/Layouts/Sidebar';

// Helpers
import { removeItem } from 'utils/storage';
*/
// Assets
import 'components/Styles/UserAccount.scss';
import HSILogo from 'assets/images/HSI_Logo.png';
import { useLocation, useNavigate } from 'react-router';
/*
// import AvatarLogo from 'assets/images/avatar.jpg';
// import { ReactComponent as UserSVG } from 'assets/svg/user_circle.svg';
*/

// Main Component
const NavBar = () => {
/*  const dispatch = useDispatch();

  const navigate = useNavigate();

  const logOut = () => {
    removeItem('accessToken');

    setTimeout(() => {
      dispatch(logout());
      navigate('/');
    }, 100);
  }; */
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const variants = {
    open: { opacity: 1, bottom: ['10vh', '-40vh'] },
    close: { bottom: ['-40vh', '10vh'] },
  };
  /* const status = () => {
       setIsOpen((isOpen) => !isOpen);
     }; */

  return (
      <header>
        <div className='logo'>
          <img src={HSILogo} alt='' />
        </div>
        <div className='menu' onClick={() => setIsOpen((isOpen) => !isOpen)}>
          Menu
        </div>
        <motion.nav
          initial={{ opacity: 0 }}
          animate={ isOpen ? 'open' : 'close'}
          variants={ variants }
        >
          <div className='secondContainer'>
            <div className='secondLogo'>
              <img src={HSILogo} alt='' />
            </div>
            <div className='close' onClick={() => setIsOpen((isOpen) => !isOpen)}>
              Close
            </div>
          </div>
          <div className='navigationContent'>
            <div className='slogan'>
              <p>
                Touching lives through IT.
              </p>
            </div>
            <div className='links'>
              <div onClick={() => { location.path === '/' ? { /* DO nothing */ } : navigate('/'); }}>
                Home
              </div>
              <div onClick={() => { location.path === '/solution' ? { /* DO nothing */ } : navigate('/solution'); }}>
                Solution
              </div>
              <div onClick={() => { location.path === '/about' ? { /* DO nothing */ } : navigate('/about'); }}>
                About
              </div>
              <div onClick={() => { location.path === '/contact' ? { /* DO nothing */ } : navigate('/contact'); }}>
                Contact
              </div>
              <div>
                Instagram
              </div>
              <div>
                LinkedIn
              </div>
              <div>
                Facebook
              </div>
            </div>
          </div>
        </motion.nav>
      </header>
  );
};

export default NavBar;
