import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

const SlideUpText = ({ text, delay }) => {

  const controls = useAnimation();
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1, // Trigger when at least 10% of the component is visible
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isVisible) {
      controls.start('visible');
    }
  }, [isVisible, controls]);

  const letterVariants = {
    hidden: { opacity: 0, y: 60 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <motion.div
        initial='hidden'
        transition={{ duration: 0.4, delay: delay }}
        animate={controls}
        variants={letterVariants}
        ref={ref}
        style={{
          // position: 'absolute',
          top: '0',
          left: '0',
          whiteSpace: 'normal', // Allow multiple lines of text
        }}
      >
        {text}
      </motion.div>
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
        //   backgroundColor: 'red', /** debug to see the divHeight */
        }}
      />
    </div>
  );
};

export default SlideUpText;
