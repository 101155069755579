/* Core & Vendor Packages
   import { toast } from 'react-toastify'; */
import { useState, useEffect } from 'react';
import { Form, Image } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CryptoES from 'crypto-es';

// Components
import FormText from 'components/GlobalComponents/FormText';
// import ButtonClick from 'components/GlobalComponents/ButtonClick';
import ButtonSubmit from 'components/GlobalComponents/ButtonSubmit';
// import { login, selectAuth, setIsAuthenticated } from 'reducers/authReducer';

// Assets
import styles from './index.module.scss';
// import WebsiteLogo from 'assets/images/logo.png';
import LoginImage from 'assets/images/loginbg.png';

const initialState = {
  username: '',
  password: '',
};

const adminCredentials = {
  username: 'admin',
  password: 'password',
};

// Main Component
const Login = () => {
  // const dispatch = useDispatch();

  /* const authState = useSelector(selectAuth);
     const { updateProfileLogs } = authState; */

  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialState);

  /* const handleSignUp = () => {
     navigate('/sign-up');
   }; */

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData?.username === adminCredentials?.username && formData?.password === adminCredentials?.password) {
      localStorage.setItem('token', CryptoES.AES.encrypt(`${formData?.username} ${formData?.password}`, 'HSI'));
      navigate('/admin/pages');
    }
    if (formData?.username !== adminCredentials?.username || formData?.password !== adminCredentials?.password) {
      alert('Invalid Credentials');
    }
    // dispatch(login(formData));
  };

  const usernameProps = {
    type: 'text',
    name: 'Username',
    setState: setFormData,
    stateValue: formData?.username,
    placeholder: 'user',
  };

  const passwordProps = {
    type: 'password',
    name: 'Password',
    setState: setFormData,
    placeholder: '•••••••',
    stateValue: formData?.password,
  };

  const submitButtonProps = {
    value: 'Sign In',
    type: 'submit',
    variant: 'primary',
    // isDisabled: updateProfileLogs?.isLoading,
  };

  /* const signupButtonProps = {
    value: 'Sign Up',
    variant: 'secondary',
    handleClick: handleSignUp,
  }; */

  // renderSubmitCallback(updateProfileLogs, dispatch);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/admin/pages');
    }
  }, []);

  return (
    <div className={styles?.loginContainer}>
      <Image src={LoginImage} alt='Login Bg' className={`position-absolute z-1 ${styles?.imageBG} `} />
      <Form className={`shadow-sm ${styles?.loginForm} position-relative z-2 bg-light`} onSubmit={handleSubmit}>
        
        <FormText className={styles?.form_input_lg} {...usernameProps} />

        <FormText className={styles?.form_input_lg} {...passwordProps} />

        <div className={styles?.marginContainer}>
          <Link to='/account-recovery'>Forgot Password?</Link>

          <ButtonSubmit {...submitButtonProps} />

          {/* <ButtonClick {...signupButtonProps} /> */}
        </div>
      </Form>
    </div>
  );
};

/* const renderSubmitCallback = (updateProfileLogs, dispatch) => {
     useEffect(() => {
       const { error, isFailed, isSuccess } = updateProfileLogs; */

/*     if (isSuccess) {
         setTimeout(() => {
           dispatch(setIsAuthenticated());
         }, [2000]);
       } */

/*     if (isFailed) {
         toast.error(error);
       }
     }, [updateProfileLogs]);
   }; */

export default Login;
