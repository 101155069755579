import React, { useState } from 'react';
import PrivateLayout from 'components/GlobalComponents/PrivateLayout';
import TextEditor from 'components/GlobalComponents/TextEditor';
import styles from './index.module.scss';

import AdministrativeAndArchitecture from '../../assets/images/Administrative and Architecture.png';
import CustomMobileAppDevelopment from '../../assets/images/Custom Mobile App Development.png';
import Cybersecurity from '../../assets/images/Cybersecurity.png';
import Workforce from '../../assets/images/Workforce.png';
import ConsultationAndDesignThinking from '../../assets/images/Consultation and Design Thinking.png';
import MicrosoftAndAWSProducts from '../../assets/images/Microsoft and AWS products.png';
import WebPresence from '../../assets/images/Web presence.png';
import PowerAppsAndProductDevelopment from '../../assets/images/Power apps and Product Development.png';
import SpecializedEngineering from '../../assets/images/Specialized Engineering.png';
import BrandAndMultimedia from '../../assets/images/Brand and Multimedia.png';
import CloudSolutions from '../../assets/images/Cloud Solutions.png';

import { motion } from 'framer-motion';

const defaultButtonStyle = {
    flexRadio: { backgroundColor: '#EFC72F', borderStyle: 'none', padding: '10px', borderRadius: '25px' },
    gridRadio: { backgroundColor: 'transparent', border: '1px solid', padding: '10px', borderRadius: '25px' },
};

const defaultPictureDescriptionStyle = {
    color: 'rgb(250, 250, 250)',
    backgroundColor: 'transparent',
};

const pictureContentData = {
    CustomMobileAppDevelopment: `width: 50vw; height: 100vh; position: relative; grid-area: 1 / 1 / 3 / 3;`,
    AdministrativeAndArchitecture: `width: 50vw; height: 50vh; position: relative; grid-area: 1 / 3 / 2 / 5;`,
    Cybersecurity: `width: 50vw; height: 50vh; position: relative; grid-area: 3 / 2 / 4 / 4;`,
    Workforce: `width: 50vw; height: 50vh; position: relative; grid-area: 4 / 3 / 5 / 5;`,
    ConsultationAndDesignThinking: `width: 50vw; height: 50vh; position: relative; grid-area: 5 / 2 / 6 / 4;`,
    MicrosoftAndAWSProducts: `width: 50vw; height: 75vh; position: relative; grid-area: 6 / 1 / 8 / 3;`,
    WebPresence: `width: 50vw; height: 50vh; position: relative; grid-area: 6 / 3 / 7 / 5;`,
    PowerAppsAndProductDevelopment: `width: 50vw; height: 50vh; position: relative; grid-area: 8 / 2 / 9 / 4;`,
    SpecializedEngineering: `width: 50vw; height: 50vh; position: relative; grid-area: 9 / 3 / 10 / 5;`,
    BrandAndMultimedia: `width: 50vw; height: 50vh; position: relative; grid-area: 10 / 2 / 11 / 4;`,
    CloudSolutions: `width: 50vw; height: 50vh; position: relative; grid-area: 11 / 1 / 12 / 3;`,
};

const revealVariants = {
    initial: {
      transition: { staggerChildren: 0.07, delayChildren: 0.02 },
    },
  
    active: {
      transition: { staggerChildren: .10, ease: 'easeOut' },
    },
};
/*
const pVariants = {
    initial: {
        opacity: 100, translateY: 80,
    },

    active: {
        opacity: 100, translateY: -25,
        transition: { duration: 0.5, ease: 'easeOut' },
    },
};
*/
const Solution = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [section1MainContent, setSection1MainContent] = useState('<p style="font-size: 3.6vw">Each production below has won multiple awards. We don\'t just work for prizes, but they are a testimony that we love our job and do it well.</p>');

    const [buttonStyle, setButtonStyle] = useState(defaultButtonStyle);

    const [picturesContentDisplay, setPicturesContentDisplay] = useState('display: flex; gap: 0px; flex-direction: column;');
    const [pictureContent, setPictureContent] = useState(`width: 100vw; height: 100vh; position: relative;`);
    const [pictureContentDescription, setPictureContentDescription] = useState(defaultPictureDescriptionStyle);

    const picturesContent = `
    <div style="${picturesContentDisplay}">
        <div style="${pictureContent?.CustomMobileAppDevelopment ? pictureContent?.CustomMobileAppDevelopment : pictureContent}">
            <img src="${CustomMobileAppDevelopment}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Custom Mobile App Development</p>
                <p style="color: rgb(239, 199, 47);">(01)</p>
            </div>
        </div>
        <div style="${pictureContent?.AdministrativeAndArchitecture ? pictureContent?.AdministrativeAndArchitecture : pictureContent}">
            <img src="${AdministrativeAndArchitecture}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Administrative and Architecture</p>
                <p style="color: rgb(239, 199, 47);">(02)</p>
            </div>
        </div>
        <div style="${pictureContent?.Cybersecurity ? pictureContent?.Cybersecurity : pictureContent}">
            <img src="${Cybersecurity}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Cybersecurity</p>
                <p style="color: rgb(239, 199, 47);">(03)</p>
            </div>
        </div>
        <div style="${pictureContent?.Workforce ? pictureContent?.Workforce : pictureContent}">
            <img src="${Workforce}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Workforce</p>
                <p style="color: rgb(239, 199, 47);">(04)</p>
            </div>
        </div>
        <div style="${pictureContent?.ConsultationAndDesignThinking ? pictureContent?.ConsultationAndDesignThinking : pictureContent}">
            <img src="${ConsultationAndDesignThinking}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Consultation and Design Thinking</p>
                <p style="color: rgb(239, 199, 47);">(05)</p>
            </div>
        </div>
        <div style="${pictureContent?.MicrosoftAndAWSProducts ? pictureContent?.MicrosoftAndAWSProducts : pictureContent}">
            <img src="${MicrosoftAndAWSProducts}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Microsoft and AWS products</p>
                <p style="color: rgb(239, 199, 47);">(06)</p>
            </div>
        </div>
        <div style="${pictureContent?.WebPresence ? pictureContent?.WebPresence : pictureContent}">
            <img src="${WebPresence}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Web Presence</p>
                <p style="color: rgb(239, 199, 47);">(07)</p>
            </div>
        </div>
        <div style="${pictureContent?.PowerAppsAndProductDevelopment ? pictureContent?.PowerAppsAndProductDevelopment : pictureContent}">
            <img src="${PowerAppsAndProductDevelopment}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Power Apps and Product Development</p>
                <p style="color: rgb(239, 199, 47);">(08)</p>
            </div>
        </div>
        <div style="${pictureContent?.SpecializedEngineering ? pictureContent?.SpecializedEngineering : pictureContent}">
            <img src="${SpecializedEngineering}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Specialized Engineering</p>
                <p style="color: rgb(239, 199, 47);">(09)</p>
            </div>
        </div>
        <div style="${pictureContent?.BrandAndMultimedia ? pictureContent?.BrandAndMultimedia : pictureContent}">
            <img src="${BrandAndMultimedia}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Brand and Multimedia</p>
                <p style="color: rgb(239, 199, 47);">(10)</p>
            </div>
        </div>
        <div style="${pictureContent?.CloudSolutions ? pictureContent?.CloudSolutions : pictureContent}">
            <img src="${CloudSolutions}" alt="" style="position: absolute; width: 100%; height: 100%; object-fit: cover; z-index: -1;">
            <div style="background-color: ${pictureContentDescription?.backgroundColor}; width: 100%; padding: 1% 10%; display: flex; align-items: center; justify-content: space-between; position: absolute; bottom: 0px;">
                <p style="color: ${pictureContentDescription?.color};">Cloud Solutions</p>
                <p style="color: rgb(239, 199, 47);">(11)</p>
            </div>
        </div>
    </div>
    `;

    const toggleDisplay = (displayType) => {
        if (displayType === 'flex') {
            setButtonStyle({ ...defaultButtonStyle });
            setPicturesContentDisplay('display: flex; gap: 0px; flex-direction: column;');
            setPictureContent('width: 100vw; height: 100vh; position: relative;');
            setPictureContentDescription({ ...defaultPictureDescriptionStyle });
        } else if (displayType === 'grid') {
            setButtonStyle({
                flexRadio: { backgroundColor: 'transparent', border: '1px solid', padding: '10px', borderRadius: '25px' },
                gridRadio: { backgroundColor: '#EFC72F', borderStyle: 'none', padding: '10px', borderRadius: '25px' },
            });
            setPicturesContentDisplay('display: grid; gap: 10px; grid-template-columns: 1fr 1fr 1fr 1fr;');
            setPictureContent(pictureContentData);
            setPictureContentDescription({
                color: 'rgb(32, 33, 35)',
                backgroundColor: 'rgb(250, 250, 250)',
            });
        }
    };

    return (
        <PrivateLayout pageTitle='Solution' className={styles.solution}>
            <section className={styles?.section1} style={{ minHeight: '100vh', padding: '0 10% 0 10%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <div className={styles?.circleShape} style={{ position: 'absolute', top: '-40vh', height: '60vh', width: '60vh', borderRadius: '50%', transform: 'rotateZ(-40deg)', backgroundImage: 'linear-gradient(rgb(233, 190, 20), rgba(239, 201, 47, 0.253))', zIndex: '0' }}></div>
                
                <motion.div
                  className={styles?.headerContainer}
                  style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', gap: '2rem', justifyContent: 'center', alignItems: 'center', position: 'relative' }}
                  variants={revealVariants}
                  initial='initial'
                  whileInView='active'
                >
                    {/* <span className={styles?.headerLine}>
                        <motion.p
                          variants={pVariants}
                        >
                            Each production below has won multiple awards.
                        </motion.p>
                    </span>
                        
                    <span className={styles?.headerLine}>
                        <motion.p
                          variants={pVariants}
                        >
                            We don&apos;t just work for prizes, but they are a
                        </motion.p>
                    </span>

                    <span className={styles?.headerLine}>
                        <motion.p
                          variants={pVariants}
                        >
                            testimony that we love our job and do it well.
                        </motion.p>
                    </span> */}
                    <motion.button initial={{ opacity: .5 }} whileHover={{ opacity: 1, scale: 1.1 }} whileTap={{ opacity: 1, scale: .9 }} className={styles?.editNSave} style={{ position: 'fixed', left: '5%', top: '50%', zIndex: '3', padding: '1vw', opacity: '.5' }} onClick={() => { setIsEdit((isEdit) => !isEdit); }}>{isEdit ? 'Save' : 'Edit'}</motion.button>
                    {isEdit ? (
                    <TextEditor text={section1MainContent} setText={setSection1MainContent} />
                    ) : (
                    <div className={styles?.section1MainContent} dangerouslySetInnerHTML={{ __html: section1MainContent }}></div>
                    )}
                </motion.div>       
            </section>

            <section className={styles?.section2} style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem', justifyContent: 'space-around', alignItems: 'center' }}>
                <div className={styles?.radios} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem 0', gap: '1rem' }}>
                    <div className={styles?.radio} style={{ position: 'relative' }}>
                        <input style={{ position: 'absolute', width: '100%', height: '100%', opacity: '0' }} type='radio' name='display' value='flex' onChange={(e) => { toggleDisplay(e.target.value); }} />
                        <label htmlFor='' style={buttonStyle?.flexRadio}>Full Size Grid</label>
                    </div>
                    <div className={styles?.radio} style={{ position: 'relative' }}>
                        <input style={{ position: 'absolute', width: '100%', height: '100%', opacity: '0' }} type='radio' name='display' value='grid' onChange={(e) => { toggleDisplay(e.target.value); }} />
                        <label htmlFor='' style={buttonStyle?.gridRadio}>Dispatched Grid</label>
                    </div>
                </div>
                    <div dangerouslySetInnerHTML={{ __html: picturesContent }}></div>
            </section>
        </PrivateLayout>
    );
};

export default Solution;
