import React, { useEffect, useState } from 'react';
import { Box, Image, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Card, CardBody, Input, Button, Flex, Spacer, Badge, ButtonGroup, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, FormControl, FormLabel, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';

// Components
import AdminLayout from 'components/GlobalComponents/AdminLayout';

import styles from './index.module.scss';

import ExternalIcon from 'assets/images/CkExternalLink.svg';
import PolygonAdmin from 'assets/images/Polygon_Admin.png';
import axios from 'axios';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

const AdminPages = () => {

  const [viewAllPages, setViewAllPages] = useState({});

  useEffect(() => {
    if (Object.keys(viewAllPages).length === 0) {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/page`).then((res) => {
        console.log('res', res);
        setViewAllPages(res);
      });
    }
  }, [viewAllPages]);

  return (
    <AdminLayout pageTitle='Pages'>
      <Box className={styles?.pages}>  
        <Image src={PolygonAdmin} className={styles?.polygon_admin} />
        <Box p={5}>  
          <p className={styles?.p}>Pages</p>

          <Card>
            <CardBody>
              <Flex alignItems='center' marginBottom={'22px'}>
              <Input
                placeholder='Filter Pages'
                size='sm'
                type='search'
                width={'506px'}
              />
                <Spacer />
                <NewPageModal />
              </Flex>
            
              <Card>
                <CardBody>
                  
                  <TableContainer>
                  <Table variant='striped' colorScheme='gray'>
                    <Thead fontStyle={'normal'}>
                      <Tr>
                        <Th>Title</Th>
                        <Th>Status</Th>
                        <Th>Created</Th>
                        <Th>Updated</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.keys(viewAllPages).length !== 0 ? viewAllPages.data.data.map((page) => (
                        <Tr key={page.id}>
                          <Td>{page.title}</Td>
                          <Td><Badge colorScheme='green'>Published</Badge></Td>
                          <Td>{moment(page.created_at).format('MMMM DD, YYYY')}</Td>
                          <Td>{moment(page.updated_at).format('MMMM DD, YYYY')}</Td>
                          <Td>
                            <ButtonGroup spacing='2'>
                              <EditPageModal pageId={page.id} pageTitle={page.title} />
                              <DeletePageModal pageId={page.id} />
                            </ButtonGroup>
                          </Td>
                        </Tr>
                      )) : (
                        <Tr>LOADING</Tr>
                      )}
                      <Tr>
                        <Td>Contact</Td>
                        <Td><Badge colorScheme='green'>Published</Badge></Td>
                        <Td>Sept 18, 2023</Td>
                        <Td>Sept 18, 2023</Td>
                        <Td>
                          <ButtonGroup spacing='2'>
                            <EditPageModal />
                            <DeletePageModal />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Admin</Td>
                        <Td><Badge colorScheme='red'>Unpublished</Badge></Td>
                        <Td>Sept 18, 2023</Td>
                        <Td>Sept 18, 2023</Td>
                        <Td>
                          <ButtonGroup spacing='2'>
                            <EditPageModal />
                            <DeletePageModal />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Box>
      </Box>
    </AdminLayout>
  );
};

function OverlayAlert({ isAlertVisible, status, title, description }) {
  return (
    <Box position='fixed' top='92' right='4' zIndex='9999'>
      {isAlertVisible && (
        <Alert status={status} variant='top-accent' borderRadius='md' boxShadow='md' minW='400px'>
          <AlertIcon boxSize='2em' mr={4} />
          <Box flex='1'>
            <AlertTitle mr={2}>{title}</AlertTitle>
            <AlertDescription>{description}</AlertDescription>
          </Box>
        </Alert>
      )}
    </Box>
  );
}

function NewPageModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const [createPageFormData, setCreatePageFormData] = useState({
    title: '',
  });

  const onCloseAlert = () => {
    setIsAlertVisible(false);
  };

  const showAlert = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      onCloseAlert();
      window.location.reload();
    }, 1000);
  };

  const createPage = () => {
    if (createPageFormData?.title === '') {
      alert('form is empty');
    }
    if (createPageFormData?.title !== '') {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/page`, createPageFormData).then((res) => {
        console.log('res1', res);
        onClose();
        showAlert();
      });
      setCreatePageFormData({ ...createPageFormData, title: '' });
    }
  };

  return (
    <>
      <Button backgroundColor={'#EFC72F'} size='md' onClick={onOpen}>New Page</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl marginY='2'>
              <h5>New Page</h5>
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Page Title</FormLabel>
                <Input 
                  type='text' 
                  onChange={(e) => { setCreatePageFormData({ ...createPageFormData, title: e.target.value }); }} 
                />
            </FormControl>
            {/* <FormControl marginBottom='2'>
                <FormLabel>Status</FormLabel>
                <Select 
                  useBasicStyles
                  placeholder='Select status' 
                  options={[
                    { value: 'published', label: 'Published' },
                    { value: 'unpublished', label: 'Unpublished' },
                  ]}
                />
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Creation Date</FormLabel>
                <Input type='date' />
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Update Date</FormLabel>
                <Input type='date' />
            </FormControl>
            <FormControl>
                <FormLabel>
                    Tags/Slugs
                </FormLabel>
                <Select
                  isMulti
                  useBasicStyles
                  name='tags'
                  options={[
                    { value: 'lorem', label: 'Lorem' },
                    { value: 'ipsum', label: 'Ipsum' },
                    { value: 'dolor', label: 'Dolor' },
                    { value: 'sit', label: 'Sit' },
                    { value: 'amet', label: 'Amet' },
                ]}
                  closeMenuOnSelect={false}
                />
            </FormControl> */}
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>Cancel</Button>
            <Button backgroundColor={'#EFC72F'} onClick={createPage}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <OverlayAlert isAlertVisible={isAlertVisible} status='success' title='Page Created' description='Operation Successful' />
    </>
  );
}

function EditPageModal({ pageId, pageTitle }) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [editPageFormData, setEditPageFormData] = useState({
    title: pageTitle,
  });

  const onCloseAlert = () => {
    setIsAlertVisible(false);
  };

  const showAlert = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      onCloseAlert();
      window.location.reload();
    }, 1000);
  };

  const updatePage = () => {
    if (editPageFormData?.title === '') {
      alert('form is empty');
    }
    if (editPageFormData?.title !== '') {
      axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/page/${pageId}`, editPageFormData).then((res) => {
        console.log('res3', res);
        onClose(); 
        showAlert();
      });
      setEditPageFormData({ ...editPageFormData, title: '' });
    }
  };

  return (
    <>
      <Button backgroundColor={'#FAFAFA'} size='md' onClick={onOpen}>Edit</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl marginY='2'>
              <h5>Manage Page</h5>
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Page Title</FormLabel>
                <Input type='text' value={editPageFormData?.title} onChange={(e) => { setEditPageFormData({ ...editPageFormData, title: e.target.value }); }} />
            </FormControl>
            {/* <FormControl marginBottom='2'>
                <FormLabel>Status</FormLabel>
                <Select 
                  useBasicStyles
                  placeholder='Select status' 
                  options={[
                    { value: 'published', label: 'Published' },
                    { value: 'unpublished', label: 'Unpublished' },
                  ]}
                />
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Creation Date</FormLabel>
                <Input type='date' />
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>Update Date</FormLabel>
                <Input type='date' />
            </FormControl>
            <FormControl marginBottom='2'>
                <FormLabel>
                    Tags/Slugs
                </FormLabel>
                <Select
                  isMulti
                  useBasicStyles
                  name='tags'
                  options={[
                    { value: 'lorem', label: 'Lorem' },
                    { value: 'ipsum', label: 'Ipsum' },
                    { value: 'dolor', label: 'Dolor' },
                    { value: 'sit', label: 'Sit' },
                    { value: 'amet', label: 'Amet' },
                ]}
                  closeMenuOnSelect={false}
                />
            </FormControl> */}
            <Button w='100%' background='gray.200' onClick={() => { navigate(`/admin/pages/edit/${pageId}`); }}>
              <Image src={ExternalIcon} mr={3} />
                Go to In-line Editor
            </Button>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant={'ghost'} mr={3}>Cancel</Button>
            <Button variant={'outline'} colorScheme='red' mr={3}>Delete</Button>
            <Button backgroundColor={'#EFC72F'} onClick={updatePage}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <OverlayAlert isAlertVisible={isAlertVisible} status='success' title='Page Modified' description='Operation Successful' />
    </>
  );
}

function DeletePageModal({ pageId }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const onCloseAlert = () => {
    setIsAlertVisible(false);
  };

  const showAlert = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      onCloseAlert();
      window.location.reload();
    }, 1000);
  };

  const deletePage = () => {
    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/page/${pageId}`).then((res) => { 
      console.log('res2', res); 
      onClose(); 
      showAlert();
    });
  };

  return (
    <>
      <Button colorScheme='red' size='md' onClick={onOpen}>Delete</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl marginY='2'>
              <h5>Delete Page</h5>
            </FormControl>
            Are you sure you want to delete this page?
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>Cancel</Button>
            <Button colorScheme='red' onClick={deletePage}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <OverlayAlert isAlertVisible={isAlertVisible} status='success' title='Page Deleted' description='Operation Successful' />
    </>
  );
}

export default AdminPages;
